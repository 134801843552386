import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import Layout from '@sus-core/components/scaffolding/Layout';

import { SubCategoryGrid } from '@sus-core/components/category/SubCategoryGrid';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import clsx from 'clsx';
import { PageProvider } from '@sus-core/hooks/page/PageContext';

import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';

type CategoryPageProps = SusPageProps<
  GatsbyTypes.categoryPageQuery,
  { categoryId: string }
>;

function CategoryPage({ data, location, pageContext }: CategoryPageProps) {
  const category = data.magentoCategory;
  const imageData = data.magentoCategory?.children;

  const description = category.description;

  const { meta_description, meta_keywords, meta_title, canonical_url } =
    data.magentoCategory;

  const childCategories = useMemo(
    () => category.children.filter(cat => cat.include_in_menu),
    [category?.children]
  );

  return (
    <PageProvider
      data={{
        pageType: 'Category',
        category: category.name,
        categoryId: category.magento_id,
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <Layout
        location={location}
        meta={{
          title: meta_title,
          description: meta_description,
          keywords: meta_keywords,
          robots: ['index', 'follow'],
          canonical: canonical_url,
        }}
        showBreadcrumbs
        currentPageName={category?.name}
        content={{ heading: category.name, html: description }}>
        <SubCategoryGrid
          images={imageData}
          className={clsx(PAGE_CONTAINER_CSS)}
          categories={childCategories}
        />
      </Layout>
    </PageProvider>
  );
}

export default CategoryPage;

export const query = graphql`
  query categoryPage($catId: Int!) {
    magentoCategory(magento_id: { eq: $catId }) {
      canonical_url
      id
      magento_id
      name
      meta_title
      meta_keywords
      meta_description
      description
      position
      children {
        ... on MagentoCategory {
          magento_id
          include_in_menu
          name
          canonical_url
          position
          image {
            large: childImageSharp {
              gatsbyImageData(
                breakpoints: [200]
                height: 305
                width: 508
                transformOptions: { fit: CONTAIN }
              )
            }
            small: childImageSharp {
              gatsbyImageData(
                breakpoints: [200]
                height: 195
                width: 325
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
      }
    }
  }
`;
